import React from 'react';
import { useSelector } from 'react-redux';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Avatar from 'components/images/Avatar';
import { useAuth } from 'hooks/useAuth';
import AccountMenu from 'components/elements/AccountMenu';

const UserProfileContainer = styled(Grid)({
    height: '59px',
    width: 'auto',
    padding: '0 0 0 16px',
});

const PageTitle = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '2.3rem',
    lineHeight: '1.4',

    // [theme.breakpoints.up('lg')]: {
    //     fontSize: '2.7rem',
    // },
}));

const SubPageTitle = styled(Typography)(({ theme }) => ({
    color: '#666666',
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.8rem',
    paddingTop: '0',

    // [theme.breakpoints.up('lg')]: {
    //     fontSize: '1rem',
    // },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme }) => ({
    padding: theme.spacing(0, 3, 0, 3),
    backgroundColor: 'white',
    boxShadow: 'none',
}));

const Header = () => {
    const activePageTitle = useSelector((state) => state.app.activePageTitle);
    const profile = useSelector((state) => state.auth.profile);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { keycloak, initialized } = useAuth();

    const handleLogout = async (e) => {
        e.preventDefault();

        if (initialized) {
            keycloak.logout();
            localStorage.clear();
        }
    };

    return (
        <AppBar position="static" sx={{ paddingLeft: '0', paddingTop: '0.2em', paddingBottom: '0.6em' }}>
            <Toolbar disableGutters>
                <Grid container flexWrap="nowrap" justifyContent={'space-between'}>
                    <Grid item>
                        <PageTitle data-testid={'page-title'}>{activePageTitle.pageTitle}</PageTitle>
                        <SubPageTitle data-testid={'sub-page-title'}>{activePageTitle.pageSubTitle}</SubPageTitle>
                    </Grid>

                    <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        flexWrap="nowrap"
                        alignItems="center"
                        lg={4}
                        md={5}
                    >
                        <UserProfileContainer
                            item
                            container
                            alignItems="center"
                            flexWrap="nowrap"
                            onClick={handleClick}
                        >
                            <Avatar userName={profile ? profile.name ?? profile.preferred_username : ''} />
                        </UserProfileContainer>

                        <AccountMenu
                            handleClose={handleClose}
                            open={open}
                            anchorEl={anchorEl}
                            profile={profile}
                            handleLogout={handleLogout}
                        />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
