import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from 'react-redux';

import useEvidences from 'hooks/evidences/useEvidences';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import {notifyChangeEvidencesSelectedPaper} from 'state/app';
import AlertCard from 'components/elements/AlertCard';

import EvidenceGridCard from './evidence-grid-card';
import EvidenceCardView from './evidence-card-view';


const EvidenceGrid = ({
                          height, refresh = 0, sort = {
        name: '',
        order: '',
    }, selectedEvidences, handleSelect, selectAll = false, handleCount
                      }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const selectedVertical = useSelector((state) => state.app.vertical);
    const filters = useSelector((state) => state.app.evidencesPage.filters);
    const selectedPaper = useSelector((state) => state.app.evidencesPage.selectedPaper);

    const [flattenedResults, setFlattenedResults] = useState([]);

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch, isFetched} = useEvidences({
        filters,
        sort,
        vertical: selectedVertical,
        enabled: selectedVertical !== ''
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
            if (data.pages.length > 0) {
                handleCount({
                    total_count: data.pages[0].meta['total_count'],
                    not_extracted_count: data.pages[0].meta['not_extracted_count'],
                    success_count: data.pages[0].meta['success_count'],
                    partial_count: data.pages[0].meta['partial_count'],
                    failed_count: data.pages[0].meta['failed_count']
                })
            } else {
                handleCount({
                    total_count: 0,
                    not_extracted_count: 0,
                    success_count: 0,
                    partial_count: 0,
                    failed_count: 0
                })
            }
        }
    }, [data])

    useEffect(() => {
        if (flattenedResults && flattenedResults.length > 0) {
            dispatch(notifyChangeEvidencesSelectedPaper(flattenedResults[0]));
        }
    }, [flattenedResults])

    useEffect(() => {
        refetch()
    }, [refresh])

    useEffect(() => {
        dispatch(notifyChangeEvidencesSelectedPaper(null));
    }, [filters, selectedVertical])

    const handleClick = (paper) => {
        dispatch(notifyChangeEvidencesSelectedPaper(paper));
    }

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'success' && flattenedResults && flattenedResults.length === 0) {
        return <Grid item xs={12} container spacing={1}>
            <AlertCard severity={'info'} message={'No data available'} height={height}/>
        </Grid>
    }

    return <Grid item xs={12} container spacing={2}>

        <Grid item xs={4}>
            {flattenedResults && <InfiniteLoaderList
                height={height - 8}
                data={flattenedResults}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isError={isError}
                itemTemplate={({data, index}) => <EvidenceGridCard data={data} index={index}
                                                                   handleClick={handleClick}
                                                                   selected={selectedPaper && selectedPaper.paper_id === data.paper_id}
                                                                   handleRefetch={refetch}
                                                                   selectedEvidences={selectedEvidences}
                                                                   handleSelect={handleSelect}
                                                                   selectAll={selectAll}
                />}
            />}
        </Grid>

        <Grid item xs={8}>
            {selectedPaper && <EvidenceCardView data={selectedPaper} handleRefetch={refetch} height={height}/>}
        </Grid>
    </Grid>
};

export default EvidenceGrid;
